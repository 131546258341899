import APP_CONFIG from "@/apps/core/modules/config";
import ExcelBase from "@/apps/core/modules/excelBase";
import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";


class TemplateExcel extends ExcelBase {
  constructor(onSaved) {
    super();
    this.matkul = null;
    this.onSaved = onSaved;
  }

  setMatkul(matkul) {
    this.matkul = matkul;
  }

  async load() {
    this.uploadFile();
  }

  uploadFile() {
    const fname = `${this.matkul.nama}_${this.getCurrentDatetime()}.xlsx`.split(" ").join("_");
    // aws
    let awsUrl = `${APP_CONFIG.baseAPIURL}/aws/`;
    axios
      .get(awsUrl, { params: { app_name: "templatematkul", filename: fname } })
      .then(response => {
        let resp_data = JSON.parse(JSON.stringify(response.data));
        let s3 = resp_data.s3;
        let fields = s3.fields;
        const formData = new FormData();
        for (const [key, val] of Object.entries(fields)) {
          formData.append(key, val);
        }
        formData.append("file", this.fl, fname);
        axios
          .post(s3.url, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            let templateUrl = s3.url + encodeURI(fields.key);
            let data = {template: templateUrl};
            this.saveTemplateMatkul(data);
          })
          .catch(() => {
            this.reset();
            Toast.open("File gagal di upload.");
          });
      })
      .catch(() => {
        this.reset();
        Toast.open("File gagal di upload.");
      });
  }

  saveTemplateMatkul(data) {
    const postUrl = `${APP_CONFIG.baseAPIURL}/matakuliah/${this.matkul.id}/`;
    axios
      .patch(postUrl, data)
      .then(response => {
        this.fl = null;
        this.reset();
        this.matkul.template = response.data.template;
        if (this.onSaved) this.onSaved(response.data.template);
      })
      .catch(error => {
        this.reset();
        if (error.response.status === 400) {
          // Perlu handle 403 juga
          Toast.open("File gagal disimpan.");
        }
      });
  }

//   reset() {
//     super.reset();
//     this.nilaiJsonArr = []; // reset ketika file null
//     this.pspdJsonArr = []; // reset ketika file null
//     // this.matkul = null;
//     this.token = null;
//     this.nilaiSummary = [];
//   }

  loadMessage(errorMessages) {
    let message = '';
    if (errorMessages.errors) {
      message = '<strong>Data Nilai:</strong>';
      message += '<ul style="list-style: square; padding: 15px;">';
      for (const error of errorMessages.errors) {
        let errorText = '';
        for (const [key, val] of Object.entries(error.errors)) {
          if (Array.isArray(val)) {
            errorText += `<p>${key}: ${val[0]}</p>`;
          } else {
            for (const [field, msg] of Object.entries(val)) {
              errorText += `<p>${field}: ${msg[0]}</p>`;
            }
          }
        }
        message += `<li :key="${error.nama}">No Urut ke ${error.row_num} ${error.nama} :<br>${errorText}</li>`;
      }
      message += '</ul>';
    }
    return message;
  }
}

export default TemplateExcel;