import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "mataKuliahList";
const modelPath = "/matakuliah/";

class MataKuliahList extends ListModelBase {
  constructor() {
    let filterMap = { kelompok: null }
    super(modelName, modelPath, filterMap);
  }
}

export default MataKuliahList;