<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTAR MATA KULIAH</p>
          <router-link
            v-if="isAdmin"
            :to="{ name: 'matakuliah-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <cari-input class="filter2" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="mataKuliahList"
            :mobile-cards="false"
          >
            <b-table-column v-slot="props" field="no" label="No">{{
              pagination.startRowNum + props.index
            }}</b-table-column>
            <b-table-column
              v-slot="props"
              field="matakuliah"
              label="Mata Kuliah"
            >
              <router-link
                :to="{
                  name: 'matakuliah-detail',
                  params: { id: props.row.id },
                }"
                >{{ props.row.kode }}</router-link
              >
              <br />
              {{ props.row.nama }}
              <br />
            </b-table-column>
            <b-table-column v-slot="props" field="materi" label="Materi">
              <template v-if="props.row.kompetensi_set.length > 1">
                <ol class="ol-style">
                  <li
                    v-for="kompetensi in props.row.kompetensi_set"
                    :key="kompetensi.id"
                  >
                    {{ kompetensi.kuantitatif }}
                  </li>
                </ol>
              </template>
              <!-- Akan di hapus -->
              <template v-else-if="props.row.kompetensi_set.length == 0">
                -
              </template>
              <span v-else>{{ props.row.kompetensi_set[0].kuantitatif }}</span>
            </b-table-column>
            <b-table-column v-slot="props" field="template" label="Template">
              <a
                v-if="props.row.template"
                title="Download Template"
                class="is-pulled-right"
                :href="props.row.template"
                target="_blank"
                download
              >
                <b-icon icon="download" pack="fas" />
              </a>
              <b-upload
                v-if="isAdmin"
                :key="`${props.row.id}_${fileCounter}`"
                v-model="fl"
                @input="() => upload(props.row)"
                accept=".xlsx"
              >
                <a title="Upload Template">
                  <b-icon icon="upload"></b-icon>
                </a>
              </b-upload>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import MataKuliahList from "../models/mataKuliahList.js";
import TemplateExcel from "../components/templateExcel.js";
import APP_CONFIG from "@/apps/core/modules/config";
import { mapGetters } from "vuex";

export default {
  name: "MataKuliahList",
  components: {
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
  },
  data() {
    this.mataKuliahListMdl = new MataKuliahList();
    this.listMdl = this.mataKuliahListMdl;
    this.templateExcel = new TemplateExcel(() => {
      this.fl = null;
    });
    let obv = this.mataKuliahListMdl.getObservables();
    obv.fl = null;
    obv.fileCounter = 0;
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin"]),
  },
  methods: {
    fetchData() {
      this.mataKuliahListMdl.load();
    },
    getFilename(url) {
      const arr = url.split("/");
      return decodeURI(arr[arr.length - 1]);
    },
    upload(mata_kuliah) {
      this.templateExcel.setMatkul(mata_kuliah);
      const apiUrl = `${APP_CONFIG.baseAPIURL}/matakuliah/${mata_kuliah.id}/template/`;
      this.templateExcel.setApiUrl(apiUrl);
      this.templateExcel.importFile(this.fl);
      this.fileCounter++;
    },
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin],
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.ol-style {
  margin-left: 15px;
  padding: 5px;
}

/* ::v-deep .kel-filter {
  width: 12rem;
} */
</style>
